import { Controller } from "stimulus"
import { debounce } from 'underscore';

export default class extends Controller {
  initialize() {
    this.filterBookmarkListing = debounce(this.filterBookmarkListing, 500).bind(this)
  }

  filterBookmarkListing(event) {
    this.handleSearchBookmarkListing(event)
  }

  handleSearchBookmarkListing(event) {
    let url = new URL(`${window.location.origin}/dashboard/bookmarks/bookmark_listings`)
    let params = { search: event.target.value }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    let request = new Request(url);

    fetch(request)
    .then(res => res.text())
    .then(data => {
      document.getElementById("bookmarked-listings").innerHTML = data
    })
  }
}
