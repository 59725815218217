import { Controller } from "stimulus"

export default class extends Controller {
  handleSelectedRadioBtn(){
    let status = document.getElementById('current_online_status').value
    let premium = document.getElementById('is_premium').value

    if (premium == 'false') {
      document.getElementById(`${status}-radio`).checked = true;
    }
  }
}
