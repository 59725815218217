import {Controller} from "stimulus";
import Rails from "@rails/ujs";
export default class extends Controller {
  static targets = [ "input", "count" ]

  connect() {
    this.count();
  }

  createSuccess(event) {
    history.back()
  }

  count(){
    const val = $(this.inputTarget).val().length;
    const charCount = 300;
    const text = `${charCount - val} characters left`

    if(charCount - val < 0){
      $(this.inputTarget).removeClass('border-wire-grey').addClass('border-danger');
    } else {
      $(this.inputTarget).removeClass('border-danger').addClass('border-wire-grey');
    }

    $(this.countTarget).text(text);
  }
}
