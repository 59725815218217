import consumer from "./consumer"

consumer.subscriptions.create("NotificationsChannel", {
  connected() {
   //
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
  },

  received(data) {
    const $notificationCount = $('#unread-notification-count');
    const $notificationList = $('#notifications');
    const count = data.unread_notifications;
    const date = $notificationList.find(">:first-child").find('.date-header').text();
    const currentDate = new Date().toLocaleDateString("en", {month:"short", day:"2-digit", year:"numeric"});

    if(!$notificationCount.parent().hasClass('d-flex')){
      $notificationCount.parent().removeClass('d-none').addClass('d-flex');
    }

    if(count == 0){
      count = 1;
    }

    if(date === currentDate){
      $notificationList.find(">:first-child").find('.notification-item').first().insertBefore(data.template);
    } else {
      $notificationList.prepend(`
        <div class="col-12 px-0">
          <div class="box-shadow-02 bg-white border-top border-bottom border-lightest p-16 mt-24">
            <h6 class="font-size-14 font-family-monteserrat font-weight-600 text-black mb-0 date-header">${currentDate}</h6>
            ${data.template}
          </div>
        </div>
      `)
    }

    if($('.none-notification').length == 1){
      $('.none-notification').remove();
    }
    
    $notificationCount.text(count)
  }
});
