import { Controller } from "stimulus"

export default class extends Controller {
  rate(event){
    const star = event.target.value;
    const radio = document.getElementById(`star${star}`)
    if (radio) {
      radio.checked = true
    }
  }
}
