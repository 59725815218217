import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect() {
    this.refresh();
  }

  refresh() {
    this.element.querySelectorAll('picture').forEach((element) => {
      const content = element.innerHTML;
      element.innerHTML = content;
    })
  }
}
