import { Controller } from "stimulus"
import { debounce } from 'underscore';
export default class extends Controller {
  // This method use to show selected users in To list.
  static values = { selectedCount: Number, selectedUserIDs: Array  }

  initialize() {
    this.filterUser = debounce(this.filterUser, 500).bind(this)
  }

  selectUser(){
    if(event.currentTarget.checked){
      this.selectedCountValue++
      this.selectedUserIDsValue = this.selectedUserIDsValue.concat([event.currentTarget.value])
      var selectedUsers = document.getElementById("selected-users");
      selectedUsers.insertAdjacentHTML('beforeend', `<div id="user-${event.currentTarget.value}"
                                                        class="d-flex bg-brand-primary badge-large-text px-12 py-4 text-center mt-8 mr-8">
                                                        <p class="font-size-14 font-weight-400 text-white mb-0">
                                                          ${event.currentTarget.dataset.name}
                                                        </p>
                                                      </div>`)
    }else{
      this.selectedCountValue--
      this.selectedUserIDsValue = this.selectedUserIDsValue.filter(item => item !== event.currentTarget.value)
      var selectedUsers = document.getElementById(`user-${event.currentTarget.value}`);
      selectedUsers.remove()
    }

    var chatFormSubmit = document.getElementById("chat-form-submit");
    if(this.selectedCountValue > 0){
      chatFormSubmit.classList.add("text-brand-primary");
      chatFormSubmit.disabled=false;
    }else{
      chatFormSubmit.disabled=true;
      chatFormSubmit.classList.remove("text-brand-primary");
    }
  }

  filterUser(event) {
    this.triggerUserSearch(event)
  }

  triggerUserSearch(event) {
    let url = new URL(`${window.location.origin}/chats/users`)
    let params = { search: event.target.value, checked_ids: this.selectedUserIDsValue}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    let request = new Request(url);

    fetch(request)
      .then(res => res.text())
      .then(data => {
        document.getElementById("chat_users").innerHTML = data
      })
  }

  submitForm(event) {
    const selectedUserHiddenInputs = this.selectedUserIDsValue.map(id => {
      return `
        <input type="hidden" value="${id}" name="chat[participants_attributes][][user_id]">
      `
    });
    event.target.insertAdjacentHTML('beforeend', selectedUserHiddenInputs.join());
  }
}
