import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  connect(){
    const header = document.getElementById('home-navbar');
    let sticky = header.offsetTop;
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    window.onscroll = function(){
      if (window.pageYOffset > sticky) {
        header.classList.add("sticky");
        if(!isMobile){
          header.classList.add('w-60')
        }
      } else {
        header.classList.remove("sticky");
        if(!isMobile){
          header.classList.remove('w-60')
        }
      }
    }
  }
}