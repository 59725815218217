import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ['numberInput']

    connect(){
        this.formatNumeric()
    }

    formatNumeric(){
        let input = $(this.numberInputTarget)
        let value = input.val();
        if (isNaN(value) || value === '') return;

        if(value % 1 == 0){
            input.val(+value)
        }
    }
}