import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form", "submitActions", "hiddenField", "actionField", "recordIds"]

  itemSelect(e){
    var target = $(e.target)[0]
    var checked = false

    // if(inputs){
    //   for (var i = 0; i < inputs.length; i++) {
    //     if(inputs[i].checked)
    //       checked=true
    //   }
    // }
    if(target.checked){
      $(this.formTarget).append(`<input type="hidden" name="record_ids[]" value="${e.target.value}" />`);
    } else {
      $(this.formTarget).find(`input[name="record_ids[]"][value="${e.target.value}"]`).remove();
    }

    var inputs = $(this.formTarget).find("input[name='record_ids[]']").length;
    const form = $(this.element);
    if(inputs > 0) {
      if(this.hasSubmitActionsTarget){
        this.submitActionsTarget.classList.remove("disabled")
      } else {
        form.find('.options-dropdown').removeClass('disabled');
      }
    }else {
      if(this.hasSubmitActionsTarget){
        this.submitActionsTarget.classList.add("disabled")
      } else {
        form.find('.options-dropdown').addClass('disabled');  
      }

    }
  }


  submit(){
    this.hiddenFieldTarget.value = event.currentTarget.getAttribute('data-value')
    this.actionFieldTarget.value = event.currentTarget.getAttribute('data-action-name')
    var form = this.formTarget
    Rails.fire(form, 'submit')
  }

}

