import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form"]

  submit(){

    if ($(".media-checkbox-round:checked").length > 0){
      Rails.fire(this.formTarget, 'submit');
    }
    else{
      alert('Please select at least one media');
      return;
    }
    
  }
}

