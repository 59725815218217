import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "readMore","readLess", "content", "firstContent" ]

  connect(){
    $(this.firstContentTarget).show();
    $(this.contentTarget).hide();
  }
    
  readMore() {
    if(event.currentTarget.innerHTML === "Read More") {
      this.readMoreTarget.setAttribute("class", "inline");
      this.readLessTarget.innerHTML = "Read Less"
    }
    else{
      this.readMoreTarget.setAttribute("class", "d-none");
      this.readLessTarget.innerHTML = "Read More"
    }
  }

  seeMore(e){
    e.preventDefault();
    let txt;
    if ($(this.contentTarget).is(':visible')) {
      txt = 'See More';
      $(this.firstContentTarget).show();
    } else {
      txt = 'See Less';
      $(this.firstContentTarget).hide();
    }
    $(this.readMoreTarget).text(txt);
    $(this.contentTarget).slideToggle(200);
  }
}
