import {Controller} from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  launchModal(event) {
    $("#generic-modal").modal('show');
    $('#generic_modal_content').html(event.currentTarget.firstElementChild.outerHTML);
  }
}

