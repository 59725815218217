import { Controller } from "stimulus"

export default class extends Controller {
  connect(){
    $(".carousel").carousel({
        interval:false,
        pause:true,
        touch:true
    });
  }
}