import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  showPlaceholder(event) {
    this.element.querySelectorAll('source').forEach((source) => {
      this.element.removeChild(source);
    });
    event.target.error = null;
    event.target.src = this.element.dataset.placeholder;
    event.target.style = "aspect-ratio: " + this.element.dataset.width + " / " + this.element.dataset.height;
  }
}
