import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["input", 'submit'];

  connect() {
    this.inputTarget.style.resize = 'none';
    this.inputTarget.style.minHeight = `${this.inputTarget.scrollHeight}px`;
    
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    if(!isMobile){
      this.enterSubmit();
    }
  }

  enterSubmit(){
    $(this.inputTarget).on('keypress', (e) => {
      if (e.which === 13 && !e.shiftKey && this.inputTarget.value.length > 0) {
        $(this.inputTarget).parent().find('button').trigger('click');
        $(this.inputTarget).val('');
      }
    })
  }

  resize(event){
    if (event.which === 13 && !event.shiftKey) return;
    this.inputTarget.style.height = '5px';
    this.inputTarget.style.height =  `${this.inputTarget.scrollHeight}px`;
  }

  disableSubmit(){
    if($(this.inputTarget).value === '' || $(this.inputTarget).value == undefined) return;
    $(this.submitTarget).prop('disabled', true);
  }

  enableSubmit(){
    $(this.submitTarget).prop('disabled', false);
  }
}
