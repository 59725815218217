import Sortable from "stimulus-sortable"

export default class extends Sortable {
  end({item, newIndex}) {
    if (this.element.children.length > 1) {
      const inputList = document.getElementById('listing-cover-image-inputs');
      inputList.innerHTML = '';
      for (const image of this.element.children) {
        const input = document.createElement('input');
        input.type = 'hidden';
        input.name = 'listing[cover_image_ids][]'
        input.value = image.dataset.attachmentId;

        inputList.appendChild(input);
      }
      console.log('inputList', inputList)

      super.end({item, newIndex});
    }
  }
}
