import { Controller } from "stimulus"
import Tribute from "tributejs"

export default class extends Controller {
  static targets = [ "field" ]

  connect() {
    this.editor = this.fieldTarget.editor
    this.initializeTribute()
  }

  disconnect() {
    this.tribute.detach(this.fieldTarget)
  }

  initializeTribute() {
    this.tribute = new Tribute({
      values: this.fetchUsers,
      searchOpts: {
        skip: true
      },
      selectTemplate: function(item) {
        if (typeof item === "undefined") return null;
        return "@" + item.original.value;
      },
      menuItemTemplate: function (item) {
        return item.original.content;
      },
    })
    this.tribute.attach(this.fieldTarget)
  }

  fetchUsers(text, callback) {
    fetch(`/mentions.json?query=${text}`)
      .then(response => response.json())
      .then(users => callback(users))
      .catch(error => callback([]))
  }
}
