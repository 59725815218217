import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "button" ]

  connect() {
    let mybutton = document.getElementById("btn-back-to-top");

    window.onscroll = () => {
      this.scrollFunction();
    };

    mybutton.addEventListener("click", this.backToTop);
  }

  backToTop() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  scrollFunction() {
    let mybutton = document.getElementById("btn-back-to-top");
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      mybutton.style.display = "block";
    } else {
      mybutton.style.display = "none";
    }
  }
}