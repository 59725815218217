import {Turbo, cable} from "@hotwired/turbo-rails"
import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
    this.loadImages();
    this.scrollBottom();
    document.querySelector("#chat-wrapper").addEventListener("scroll", (e) => {
      let messages = $('#chat-wrapper')
      let messageScroll = messages[0].scrollHeight - Math.floor(messages[0].scrollTop)
      scroll = messages[0].clientHeight > messageScroll - 10 && messages[0].clientHeight <= messageScroll
    })
  }

  hoverDeleteButton(e) {
    const targetId = $(e.target).data('message-id');
    if (targetId !== undefined) {
      $(`#btn-destroy-${targetId}`).removeClass('d-none').addClass('d-flex');
      $(`#btn-destroy-${targetId}`).find('a').removeClass('disabled');
    }
  }

  hideDeleteButton(e) {
    const targetId = $(e.target).data('message-id');
    $(`#btn-destroy-${targetId}`).removeClass('d-flex').addClass('d-none');
    $(`#btn-destroy-${targetId}`).find('a').addClass('disabled');
  }

  scrollBottom() {
    let messages = $('#chat-wrapper')
    if (messages && scroll) {
      messages.scrollTop(messages[0].scrollHeight);
      scroll = true
    }
  }

  loadImages() {
    setTimeout(function () {
      var images = document.querySelectorAll("[src='']");
      for (var i = 0; i < images.length; i++) {
        images[i].src = images[i].getAttribute('data-src');
        if (images[i].nextElementSibling !== undefined) {
          images[i].nextElementSibling.remove() //Remove sppinner
        }
      }

      // This will fix the problem on showing default image even though there's no error 
      var uploads = document.querySelectorAll("button > img");
      for (var i = 0; i < uploads.length; i++) {
        uploads[i].src = uploads[i].getAttribute('data-src');
        if (uploads[i].nextElementSibling !== null && uploads[i].nextElementSibling !== undefined) {
          uploads[i].nextElementSibling.remove() //Remove sppinner
        }
      }
      //window.scrollTo(0, document.body.scrollHeight);
    }, 3000)
  }

}
