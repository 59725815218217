import { Controller } from "stimulus";
import { debounce } from 'underscore';

export default class extends Controller {
  initialize() {
    this.handleSearchInputChange = debounce(this.handleSearchInputChange, 500).bind(this)
  }

  handleSearchInputChange(event) {
    this.triggerSearch(event)
  }

  triggerSearch(event) {
    let search = event.target.value === null ? '' : event.target.value
    let url = `${window.location.origin}/dashboard/orders/records?filter[search]=${search}`

    fetch(url).then(res => res.text())
              .then(data => {
                document.getElementsByClassName("records")[0].innerHTML = data
              })
  }

  closeModal(){
    $(".modal").modal('hide')
  }
}
