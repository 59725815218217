import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'parent' , 'block' ]

  reduceHeight(){
    if($(this.blockTarget).hasClass('show')){
      $(this.parentTarget).css('max-height', '');
      $(this.parentTarget).css('height', '100px');
    } else {
      $(this.parentTarget).css('height', '');
      $(this.parentTarget).css('max-height', 'calc(100vh - 5rem)');
    }
  }
}