import { Controller } from "stimulus"
import Rails, { href } from "@rails/ujs";

export default class extends Controller {
  static targets = [ "back" ]
  backBtn(){
    if (history.length > 2){
      history.back();
    }
    else{
      this.backTarget.href = this.backTarget.getAttribute('data-href');
    }
  }
}