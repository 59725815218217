// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"

Rails.start()
ActiveStorage.start()

const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

console.log('Hello World from Webpacker')

import jQuery from 'jquery';
const jstz = require('jstimezonedetect');
window.jstz = jstz;

window.$ = jQuery;

import 'popper.js'
import 'bootstrap'
import "channels"
import "controllers"
import "chartkick/chart.js"

import '../stylesheets/app.scss'
import 'trix/dist/trix.css';
import './startup_script'
import "@fortawesome/fontawesome-free/css/all"

require("trix")
require("@rails/actiontext")
