import { Controller } from "stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static values = {
    indicator: String,
    uploadType: String
  }
  static targets = ['input'];

  indicatorBlock = undefined;
  fileCount = 0;
  currentFile = 0;
  uploadStartHandler = undefined;
  fileUploadStartHandler = undefined;
  uploadProgressHandler = undefined;
  uploadEndHandler = undefined;
  beforeUnloadHandler = undefined;

  connect() {
    this.indicatorBlock = document.getElementById(this.indicatorValue);
    this.element.addEventListener('direct-uploads:start', this.uploadStartHandler = this.uploadStarted.bind(this));
    this.element.addEventListener('direct-upload:start', this.fileUploadStartHandler = this.fileUploadStarted.bind(this));
    this.element.addEventListener('direct-upload:progress', this.uploadProgressHandler = this.updateProgress.bind(this));
    this.element.addEventListener('direct-uploads:end', this.uploadEndHandler = this.uploadFinished.bind(this));
  }

  disconnect() {
    this.element.removeEventListener('direct-uploads:start', this.uploadStartHandler);
    this.element.removeEventListener('direct-upload:start', this.fileUploadStartHandler);
    this.element.removeEventListener('direct-upload:progress', this.uploadProgressHandler);
    this.element.removeEventListener('direct-uploads:end', this.uploadEndHandler);
  }

  countFiles() {
    this.fileCount = this.inputTarget.files.length
  }

  submit() {
    this.countFiles();
    Rails.fire(this.element, 'submit');
  }

  uploadStarted() {
    window.addEventListener('beforeunload', this.preventUnload);
    this.indicatorBlock.classList.remove('d-none');
    this.indicatorBlock.classList.add('d-flex');
    $('#generic-modal').modal('hide'); // jquery call because bootstrap
    $('#generic-modal-transparent').modal('hide'); // jquery call because bootstrap
    $('#joinContestModal').modal('hide'); // jquery call because bootstrap
  }

  fileUploadStarted(event) {
    this.currentFile++;
  }

  updateProgress(event) {
    this.indicatorBlock.querySelector('.progress-filename').innerText =
      `${event.detail.file.name} (${this.currentFile}/${this.fileCount})`;
    this.indicatorBlock.querySelector('progress').value = event.detail.progress;
  }

  uploadFinished() {
    window.removeEventListener('beforeunload', this.preventUnload);
    this.indicatorBlock.classList.add('d-none');
    this.indicatorBlock.classList.remove('d-flex');
  }

  preventUnload(event) {
    event.preventDefault();
    event.returnValue = true;
  }
  
  resetInput(){
    const name = $(this.inputTarget).attr('name');
    $(`input[type="hidden"][name="${name}"]`).remove();
  }
}
