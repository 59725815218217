import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "item" ]

  loadPlan(){
    document.getElementById('plan_months').innerHTML = this.itemTarget.getAttribute('data-name');
    document.getElementById('secret_plan_id').value = this.itemTarget.getAttribute('data-id');
  }

  editPlan(){
    document.getElementById('plan_month_edit').innerHTML = this.itemTarget.getAttribute('data-name');
    document.getElementById('edit_pricing_form').action = this.itemTarget.getAttribute('data-form-action');
    document.getElementById('price').value = this.itemTarget.getAttribute('data-price');
    document.getElementById('description').value = this.itemTarget.getAttribute('data-description');
  }
}