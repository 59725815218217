import consumer from "./consumer"
import { debounce } from 'underscore';

document.addEventListener('turbo:load', function() {
  const clearTypingFunc = debounce(function(){
    document.getElementsByClassName('chat-typing-indicator')[0].classList.add('d-none')
  }, 3000)

  if (document.getElementsByClassName('chat_id').length > 0) {
    const chatId = document.getElementsByClassName('chat_id')[0].value
    const currentUserId = document.getElementsByClassName('current_user_id')[0].value

    consumer.subscriptions.create({ channel: "ChatChannel", chat_id: chatId }, {
      received(data) {
        if (data.is_typing === 'true' && currentUserId != data.id) {
          document.getElementsByClassName('chat-typing-indicator')[0].classList.remove('d-none')
          document.getElementsByClassName('typing-indicator-content')[0].innerHTML = `${data.username} is typing...`

          clearTypingFunc()
        }
        else {
          document.getElementsByClassName('chat-typing-indicator')[0].classList.add('d-none')
        }
      }
    })
  } else {
    return;
  }
})
