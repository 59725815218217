document.addEventListener('turbo:load', function() {
  $('[data-bs-toggle="popover"]').popover({
    trigger: 'click',
    placement: 'bottom'
  })

  $('body').on('click', function (e) {
    $('[data-bs-toggle=popover]').each(function () {
      // hide any open popovers when the anywhere else in the body is clicked
      if (!$(this).is(e.target) && $(this).has(e.target).length === 0 && $('.popover').has(e.target).length === 0) {
        $(this).popover('hide');
      }
    });
  });

  closeAllModals();
})

document.addEventListener('turbo:before-render', () => {
  $('#flash .alert').find('button[data-dismiss="alert"]').trigger('click');
})

function closeAllModals() {

  // get modals
  const modals = document.getElementsByClassName('modal');

  // on every modal change state like in hidden modal
  for(let i=0; i<modals.length; i++) {
    modals[i].classList.remove('show');
    modals[i].setAttribute('aria-hidden', 'true');
    modals[i].setAttribute('style', 'display: none');
  }

   // get modal backdrops
   const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

   // remove every modal backdrop
   for(let i=0; i<modalsBackdrops.length; i++) {
     document.body.removeChild(modalsBackdrops[i]);
   }
}