import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["source", "destination"];

  play(event){
    const videoUrl = event.currentTarget.getAttribute("data-url")
    event.currentTarget.innerHTML = `<iframe src="${videoUrl}" class="w-100 video-iframe" frameborder="0"  allow="autoplay; encrypted-media" allowfullscreen="" style="position:relative;z-index:6;"></iframe>`
  }

}
