import {Controller} from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ['form', 'deleteForm']

  async submit() {
    if ($(this.formTarget).find('input[accept="video/*"]').length > 0) {
      const files = $(this.formTarget).find('input[accept="video/*"]')[0].files[0];
      this.getDuration(files).then(res => {
        if (res < 5) {
          alert("Invalid video. Video is less than 5 seconds");
          return false;
        } else {
          document.getElementById("media-cloud-browse").style = 'display: none !important';
          Rails.fire(this.formTarget, 'submit')
        }
      });
    } else {
      const imageFiles = $(this.formTarget).find('input[accept="image/*"]')[0].files;
      // if (this.validation(imageFiles)) {
        document.getElementById("media-cloud-browse").style = 'display: none !important';
        Rails.fire(this.formTarget, 'submit')
      // }
    }
  }

  validation(files) {
    for (let x in files) {
      const maxAllowedSize = 5 * 1024 * 1024;
      if (files[x].size > 0 && files[x].size > maxAllowedSize) {
        if (files.length > 1) {
          alert("The images are not given between size")
        } else {
          alert("The image is not given between size")
        }
        return false
      }
    }
    return true
  }

  getDuration(file){
    return new Promise((res) => {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        res(video.duration)
      }
    })
  }

  addPhotos() {
    $('.common-upload-process-bar').removeClass('d-none')
    Rails.fire(this.formTarget, 'submit')
  }

  ItemSelect() {
    var checked = false
    var inputs = this.deleteFormTarget.elements["ids[]"];
    for (var i = 0; i < inputs.length; i++) {
      if (inputs[i].checked)
        checked = true
    }
    if (checked || inputs.checked) {
      $('.bulk-btn').prop("disabled", false);
      $('.bulk-btn').removeClass("disabled");
      $('.bulk-btn i').addClass("text-black");
    } else {
      $('.bulk-btn').prop("disabled", true);
      $('.bulk-btn').addClass("disabled");
      $('.bulk-btn i').removeClass("text-black");
    }
  }

  mediaFormSubmit(event) {
    event.preventDefault();

    const dataType = event.currentTarget.getAttribute('data-type');
    if (dataType !== "delete" || confirm("Are you sure?")) {
      this.element.firstElementChild.style = "display: flex;"
      event.currentTarget.parentElement.style = "display: none;"
      var action_input = document.getElementById("action_type");
      action_input.value = dataType
      var form = document.getElementById("bulk-update-form");
      Rails.fire(form, 'submit')
    }
  }
}

