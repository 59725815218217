import consumer from "./consumer"
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

consumer.subscriptions.create("AppearanceChannel", {
  updateInterval: null,

  // Called once when the subscription is created.
  initialized() {
    this.update = this.update.bind(this)
  },

  // Called when the subscription is ready for use on the server.
  connected() {
    console.log("connected")
    this.install()
    this.update()
  },

  // Called when the WebSocket connection is closed.
  disconnected() {
    console.log("disconnected")
    this.uninstall()
  },

  // Called when the subscription is rejected by the server.
  rejected() {
    console.log("rejected")
    this.uninstall()
  },
  

  update(online_update) {
    this.documentIsActive() ? this.appear(online_update) : this.away()
  },

  appear(online_update) {
    // Calls `AppearanceChannel#appear(data)` on the server.
    console.log("appear")
    this.perform("appear", { online_update: online_update })
    //this.perform("appear", { appearing_on: this.appearingOn })
  },

  received(data) {
    const user = data.user;
    let isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

    if(!data.online){
      $(`.chat-${user.id}`).remove();
    }

    if($(`#online-user-${data.user.id}`).length > 0 || $(`.chat-${data.user.id}`).length > 0){
      return;
    } 

    if($(`#user-${data.user.id}-online`).length == 0 && data.online){
      $('#chat-collapse').find('span:contains("No one is Online")').remove();
      const userId = $('#chat-collapse').data('user-id');
      const seeAll = $('#see-all');
      const seeAllDup = seeAll.clone();
      const seeAllMobile = $('#see-all-mobile');
      const seeAllMobileDup = seeAllMobile.clone();

      const templateDesktop = data.template_desktop;
      const templateMobile = data.template_mobile;

      if (!data.blocked_user_ids.includes(userId)) {
        // For desktop
        if($('#chat-collapse').children().length >= 20){
          $('#chat-collapse').children().last().remove();
        }

        if($('#mobile-online-users').children().length >= 10){
          $('#mobile-online-users').children().last().remove();
        }
        
        if(isMobile){
        // For mobile
          $('#mobile-online-users').append(templateMobile);
          $('#mobile-online-users').append(seeAllMobileDup);

          seeAllMobile.remove();
        } else {
          $('#chat-collapse').append(templateDesktop);
          $('#chat-collapse').append(seeAllDup);
          seeAll.remove();
  
          $('#chat-collapse').find('input[name="chat[participants_attributes][][user_id]"][value=""]').val(userId);
          if (!data.show) {
            $('#chat-collapse').find('.form-chat').addClass('d-none')
          }
        }
      }
    }
  },

  away() {
    // Calls `AppearanceChannel#away` on the server.
    console.log("away")
    this.perform("away")
  },

  install() {
    window.addEventListener("focus", ()=> {console.log("focus");this.update(false)})
    window.addEventListener("blur", ()=> {console.log("blur"); this.update(false)})
    document.addEventListener("turbo:load", ()=> {console.log("turbo-load"); this.update(false)})
    document.addEventListener("visibilitychange", ()=> {console.log("visibility change"); this.update(true)})
    this.updateInterval = setInterval(this.update, (4 * 60 * 1000), true) // force online status update every 4 minutes
  },

  uninstall() {
    window.removeEventListener("focus", this.update(false))
    window.removeEventListener("blur", this.update(false))
    document.removeEventListener("turbo:load", this.update(false))
    document.removeEventListener("visibilitychange", this.update(false))
    clearInterval(this.updateInterval)
  },

  documentIsActive() {
    return document.visibilityState == "visible" && document.hasFocus()
  },

  get appearingOn() {
    const element = document.querySelector("[data-appearing-on]")
    return element ? element.getAttribute("data-appearing-on") : null
  }
})
