import { Controller } from "stimulus";
import { debounce } from 'underscore';

export default class extends Controller {

  initialize() {
    this.closeModal = debounce(this.closeModal, 1).bind(this);
  }

  closeModal() {
    $(".modal").modal('hide');
    document.getElementById('contest-vote-form').reset();
  }
}
