import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "listingForm" ]

  addVideo(event){
    $('#video_id').val(event.currentTarget.getAttribute('data-video-id'));
    Rails.fire(this.listingFormTarget, 'submit');
  }

  validateCoverPhotos(event){
    if(document.querySelectorAll('input[type="checkbox"]:checked').length > 3){
      alert('You can only select up to 3 photos for cover.');
      event.currentTarget.checked = false;
    }
  }

  connect(){
    this.localStorageKey = window.location.href.split('?')[0];
  }

  clearLocalStorage() {
    // See if there is data stored for this particular form.
    if(localStorage.getItem(this.localStorageKey) != null) {
        // Clear data from localStorage when the form is submitted.
        localStorage.removeItem(this.localStorageKey);
    }
  }

  filterCountries(){
    var input, filter, elements, a, i, txtValue;
    input = document.getElementById("country-search-input");
    filter = input.value.toUpperCase();
    elements = document.getElementsByClassName("country-search-wrap");
    for (i = 0; i < elements.length; i++) {
      a = elements[i].getElementsByTagName("label")[0];
      txtValue = a.textContent || a.innerText;
      if (txtValue.toUpperCase().indexOf(filter) > -1) {
        elements[i].setAttribute('style', 'display:flex !important');
      } else {
        elements[i].setAttribute('style', 'display:none !important');
      }
    }
  }

  handleRadioButton(event) {
    if (event.srcElement.parentNode.children.is_disabled.value === 'true') {
      event.srcElement.parentNode.nextElementSibling.classList.remove('d-none')
      event.target.disabled = true
      event.target.checked = false
    } else if (document.getElementById('boost_btn').disabled) {
      document.getElementById('boost_btn').disabled = false
    } else if (!document.getElementById('boost_btn').disabled) {
      event.target.checked = false
      document.getElementById('boost_btn').disabled = true
    }
  }
}
