import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "item" ]

  sendGift(){
    let dataBalance = parseInt(this.itemTarget.getAttribute('data-balance'));
    let dataPrice = parseInt(this.itemTarget.getAttribute('data-price'));
    if(dataBalance >= dataPrice){
      document.getElementById('make-gift').removeAttribute('hidden');
      document.getElementById('no-balance').hidden= true;
    }
    else{
      document.getElementById('make-gift').hidden = true;
      document.getElementById('no-balance').removeAttribute('hidden');
    }
  }

  changeGiftType(){
    var giftType = {
      public_gift: "Your username and message will be shown to all users.",
      private_gift: "Your username and messages will only be shown to the recipient.",
      anonymous_gift: "Your username will be hidden, message will be shown to all users."
    };

    document.getElementById('gift-type').innerHTML = giftType[this.itemTarget.value];
  }

  closeModal(){
    $(this.formTarget.dataset.modal).modal('hide')
  }

}