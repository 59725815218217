import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "inputReport", "inputBlock", "report","block","blockModel","reportForm" ]
  
  toggleBlock(){
    this.blockTarget.hidden = false;
    this.reportTarget.hidden = true;
    this.reportTarget.classList.remove('active');
    this.blockTarget.classList.add('active');
  }

  toggleReport(){
    this.blockTarget.hidden = true;
    this.reportTarget.hidden = false;
    this.blockTarget.classList.remove('active');
    this.reportTarget.classList.add('active');
  }

  openReportModel(){
    this.blockModelTarget.hidden = true;
    this.reportFormTarget.hidden = false;
    this.blockModelTarget.classList.remove('active');
    this.reportFormTarget.classList.add('active');
  }
  
}