import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = [ "form" ]

  displayProgress() {
    $(document).on('direct-upload:progress', function(evt) {
      document.getElementById('spinner-progress-filename').innerText = evt.detail.file.name;
      document.getElementById('spinner-progress').value = evt.detail.progress;
    });

    $(document).on('direct-uploads:end', function() {
      document.getElementById("global-spinner").style='display: none !important';

      $(document).off('direct-upload:progress');
      $(document).off('click.once');
      $(document).off('direct-uploads:end');
    });
  }

  submit(){
    $(document).on('click.once', function () {
      if(document.getElementById("global-spinner").style.display == 'flex'){
        alert("Navigating away will cancel uploading!");
        return false;
      }
      $(document).off('click.once');
    });

    this.displayProgress();

    document.getElementById("global-spinner").style='display: flex !important';
    Rails.fire(this.formTarget, 'submit')
  }

  triggerSubmit(){
    this.displayProgress();

    document.getElementById("global-spinner").style='display: flex !important';
    Rails.fire(this.formTarget, 'submit')
  }

  triggerUploadingAnimation() {
    document.getElementById("global-spinner").style='display: flex !important';
  }
}
