import {Controller} from "stimulus"

export default class extends Controller {
  static targets = ["output", "input", "reviewBlock"]

  readURL() {
    var input = this.inputTarget
    var output = this.outputTarget
    if (this.validation(input.files) && input.files && input.files[0]) {
      var reader = new FileReader();

      reader.onload = function () {
        output.src = reader.result
        $(output).parent().find('.custom-file-upload').css('display', 'none')
        $(output).parent().find('.custom-file-upload').removeClass('d-flex')
        $(output).parent().find('.image-preview-target').removeClass('d-none')
      }

      reader.readAsDataURL(input.files[0]);
    } else {
      input.value = "";
    }
  }

  validation(files) {
    for (let x in files) {
      const maxAllowedSize = 5 * 1024 * 1024;
      if (files[x].size > 0 && files[x].size > maxAllowedSize) {
        if (files.length > 1) {
          alert("The images are not given between size")
        } else {
          alert("The image is not given between size")
        }
        return false
      }
    }
    return true
  }

  multipleReadUrl() {
    const input = this.inputTarget
    const reviewBlock = this.reviewBlockTarget
    if (this.validation(input.files) && input.files) {
      const filesAmount = input.files.length;

      $(reviewBlock).empty();
      if (filesAmount === 0) {
        $(reviewBlock).parent().find('.custom-file-upload').addClass('d-flex')
        $('label[for="reported_account_images"]').addClass('w-100');
        return;
      }

      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = function (event) {
          $($.parseHTML('<img>'))
            .attr('src', event.target.result)
            .css('width', "inherit")
            .addClass("px-4")
            .appendTo(reviewBlock);
        }

        reader.readAsDataURL(input.files[i]);
      }
      $(reviewBlock).parent().find('.custom-file-upload').addClass('d-none')
      $(reviewBlock).parent().find('.custom-file-upload').removeClass('d-flex')
      $('label[for="reported_account_images"]').removeClass('w-100');
    }
  }

  async multipleVideosReadUrl() {
    const input = this.inputTarget
    const reviewBlock = this.reviewBlockTarget
    if (input.files) {
      const filesAmount = input.files.length;

      $(reviewBlock).empty();
      if (filesAmount === 0) {
        $(reviewBlock).parent().find('.custom-file-upload').addClass('d-flex')
        $('label[for="reported_account_images"]').addClass('w-100');
        return;
      }

      for (let i = 0; i < filesAmount; i++) {
        this.getDuration(input.files[i]).then(res => {
          if(res < 5){
            alert("Invalid video. Video is less than 5 seconds");
            return false;
          } else {
            const videoBlock = document.createElement('div');
            videoBlock.classList.add(
              'd-inline-block', 'overflow-hidden', 'mx-1', 'align-top'
            );
            videoBlock.style.width = '100px';
    
            const video = document.createElement('video');
            video.classList.add('d-block', 'bg-dark');
            video.style.width = '100px';
            video.style.height = '100px';
    
            const nameSpan = document.createElement('span');
            nameSpan.classList.add('d-block', 'font-size-14');
            nameSpan.style.whiteSpace = 'normal';
            nameSpan.innerText = input.files[i].name;
    
            videoBlock.appendChild(video);
            videoBlock.appendChild(nameSpan);
            reviewBlock.appendChild(videoBlock);
    
            const reader = new FileReader();
            reader.onload = function (event) {
              const videoSource = document.createElement('source');
              videoSource.setAttribute('src', event.target.result);
              video.appendChild(videoSource);
              video.load();
            }
    
            reader.readAsDataURL(input.files[i]);
          }
        })
      }
      $(reviewBlock).parent().find('.custom-file-upload').removeClass('d-flex')
      $(reviewBlock).parent().find('.custom-file-upload').addClass('d-none')
      $('label[for="reported_account_images"]').removeClass('w-100');
    }
  }

  getDuration(file){
    return new Promise((res) => {
      var video = document.createElement('video');
      video.preload = 'metadata';
      video.src = URL.createObjectURL(file);
      video.onloadedmetadata = function() {
        window.URL.revokeObjectURL(video.src);
        res(video.duration)
      }
    })
  }
}
