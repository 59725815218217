import { Controller } from "stimulus"
import Rails from "@rails/ujs";

export default class extends Controller {

  closeModal(){
    $(".modal").modal('hide')
  }

}
