import {Controller} from "stimulus"
import {debounce} from 'underscore';
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["form", "search", "clearicon", "searchicon", "submit"]

  initialize() {
    this.handleTypingIndicator = debounce(this.handleTypingIndicator, 500).bind(this)
  }

  reset() {
    this.element.reset()
    this.element.querySelectorAll("[type=submit],input[type=file]").forEach(e => {
      e.disabled = false
    })
  }

  connect(){
    this.clearLocalStorage();
    this.localStorageKey = window.location.href.split('?')[0];
    this.setFormData(); 
  }

  setFormData() {
    // See if there is data stored for this particular form.
    if(localStorage.getItem(this.localStorageKey) != null) {
        // We need to convert the String of data back into an Object.
        const data = JSON.parse(localStorage.getItem(this.localStorageKey));
        // This allows us to have access to this.formTarget in the loop below.
        const form = this.formTarget;
        // Loop through each key/value pair and set the value on the corresponding form field.  
        Object.entries(data).forEach(([inputName, inputValue])=>{
          let input   = form.querySelector(`[name='${inputName}']`);
          input && (input.value = inputValue);
        })
    }
  }  

  getFormData() {
    // Construct a set of of key/value pairs representing form fields and their values.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData
    const form = new FormData(this.formTarget);
    let data = []
    // Loop through each key/value pair.
    // https://developer.mozilla.org/en-US/docs/Web/API/FormData/entries#example
    for(var pair of form.entries()) {
        // We don't want to save the authenticity_token to localStorage since that is generated by Rails.
        // https://guides.rubyonrails.org/security.html#cross-site-request-forgery-csrf
        if (pair[0] != "authenticity_token") {
            data.push([pair[0], pair[1]])
        }
    }
    // Return the key/value pairs as an Object. Each key is a field name, and each value is the field value.
    // https://developer.mozilla.org/en-us/docs/Web/JavaScript/Reference/Global_Objects/Object/fromEntries
    return Object.fromEntries(data)
  }

  saveToLocalStorage() {
    const data = this.getFormData();
    // Save the form data into localStorage. We need to convert the data Object into a String.
    localStorage.setItem(this.localStorageKey, JSON.stringify(data));
  }

  clear() {
    if (this.hasSearchTarget) {
      this.searchTarget.value = ""
      this.submit();
      this.toggleIcon();
    }
  }

  clearLocalStorage() {
    // See if there is data stored for this particular form.
    if(localStorage.getItem(this.localStorageKey) != null) {
        // Clear data from localStorage when the form is submitted.
        localStorage.removeItem(this.localStorageKey);
    }
  }

  submit() {
    Rails.fire(this.formTarget, 'submit')
  }

  clearField(){
    $('#filter_search').val('');
  }

  search() {
    this.toggleIcon();
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      //Submit form with UJS
      this.submit();
    }, 1000)
  }

  closeModal() {
    $(this.formTarget.dataset.modal).modal('hide')
  }

  toggleIcon() {
    if (this.hasSearchTarget && this.hasCleariconTarget && this.hasSearchiconTarget) {
      if (this.searchTarget.value.length === 0) {
        this.searchiconTarget.style.display = "block";
        this.cleariconTarget.style.display = "none";
      } else {
        this.searchiconTarget.style.display = "none";
        this.cleariconTarget.style.display = "block";
      }
    }
  }

  passwordToggler() {
    var field = this.formTarget;
    if (field.type === "password") {
      field.type = "text";
      document.getElementById('eye-toogle').classList.remove('fa-eye-slash')
      document.getElementById('eye-toogle').classList.add('fa-eye')
    } else {
      field.type = "password";
      document.getElementById('eye-toogle').classList.remove('fa-eye')
      document.getElementById('eye-toogle').classList.add('fa-eye-slash')
    }
  }

  handleTypingIndicator(event) {
    const chat_id = document.getElementsByClassName('chat_id')[0].value

    let url = new URL(`${window.location.origin}/chats/${chat_id}/messages/typing`)
    let params = {is_typing: event.type == 'input' ? true : false}
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    let request = new Request(url);

    fetch(request).then(res => res.text()).then(data => {
    })
  }

  handleFileInput(event) {
    event.srcElement.parentNode.children.customFileLabel.innerHTML = event.srcElement.files[0].name
  }

  handleBrowseMemberTitle(event) {
    document.getElementById('browse-memeber-subtitle').innerHTML = `${event.srcElement.value.replace(/\b\w/g, l => l.toUpperCase())} Profiles`
  }

  handleShowProcessBar() {
    document.getElementsByClassName('chat-upload-process-bar')[0].classList.remove('d-none')
  }

  handleArticleShowProcessBar() {
    document.getElementsByClassName('article-upload-process-bar')[0].classList.remove('d-none')
  }

  handleCommonShowProxcessBar() {
    document.getElementsByClassName('common-upload-process-bar')[0].classList.remove('d-none')
  }

  removeDisable(){
    $(this.submitTarget).removeAttr('disabled');
    $(this.submitTarget).text('Saving Listing');
  }
}
